const productConfig = {
  hasPromotionBadge: {
    26626: true, // Mashita
    26624: true, // Purra
    26625: true, // Purra
  },
  salesPrice: { //Ticket Type ID : Full Price
    26626: 240.00, // Mashita
    26624: 102.00, // Purra
    26625: 102.00, // Purra
  },
  itemGroupOrder: [
    401, // New products
    188, // Singha Water
    283, // Purra Water
    // 189, // Singha Beer
    // 190, // Leo Beer
  ]
}

export default productConfig;