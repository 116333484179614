import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, InputGroup, Button, FormControl } from 'react-bootstrap';
import { Consumer } from '../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPercentage,
} from '@fortawesome/free-solid-svg-icons';

const DiscountBox = () => {
  const {t} = useTranslation();
  const inputRef = useRef();

  return (
    <Consumer>
      { 
        ({discountCode, discountCodeNotFound, actions}) => {
          const handleApplyCode = () => {
            actions.applyDiscountCode(inputRef.current.value.trim())
          }

          const handleSubmit = (e) => {
            e.preventDefault();
            actions.applyDiscountCode(inputRef.current.value.trim())
          }
          return (
          <Row className="discount-code-box justify-content-md-center">
            <Col className="text-center" xs={12} md={12}>
              <h6 className="text-warning">{discountCodeNotFound ? t('discount_code_not_found') : <React.Fragment>&nbsp;</React.Fragment>}</h6>
            </Col>
            <Col className="pt-1" xs={12} md={6}>
              <form onSubmit={handleSubmit}>
              <InputGroup>
                  <FormControl ref={inputRef} placeholder={t('discount_code_placeholder')} />
                <InputGroup.Append>
                  <Button onClick={handleApplyCode} variant="secondary">{t('discount_code_apply')}</Button>
                </InputGroup.Append>
              </InputGroup>
              </form>
            </Col>
          </Row>
          )
        }
      }
    </Consumer>
  )
}

export default DiscountBox;