import React, { Component } from 'react';
import axios from 'axios';
import popConfig from './config';
import gtag from './gtagHelper';

const AppContext = React.createContext();

export class Provider extends Component {
  state = {
    loading: true,
    acceptedTC: false,
    maintenance: false,
    event: {},
    newOrderUrl: '',
    itemGroups: [],
    itemsData: {},
    items: [],
    discountCode: null,
    cartItems: {
    },
    cartCount: 0,
    cartTotal: 0,
    cartMinimum: popConfig.totalOrderMinimum
  };

  convertItemsArrayToObject = (ticketGroups) => {
    let itemsData = {};
    
    ticketGroups.map(group => group.ticket_types)
    .flat()
    .forEach(item => {
      itemsData[item.id] = item;
    });

    return itemsData;
  }

  checkValidDiscountCodeFromItems = (itemsData) => {
    let hasDiscount = false;
    Object.values(itemsData).forEach((itemData) => {
      if ('discounted_price' in itemData) {
        hasDiscount = true;
      }
    })
    return hasDiscount;
  }

  // Internal methods
  getItems = () => {
    let endpoint = popConfig.endpoint;
    if (this.state.discountCode !== null) { endpoint = popConfig.endpoint + `?discount_code=${this.state.discountCode}`}
    axios.get(endpoint)
    .then((response) => {
      const itemsData = this.convertItemsArrayToObject(response.data.ticket_groups)
      if (this.state.discountCode !== null) {
        this.setState({discountCodeNotFound: !this.checkValidDiscountCodeFromItems(itemsData)});
      }

      this.setState({
        loading: false,
        event: response.data.event,
        itemGroups: response.data.ticket_groups,
        itemsData: itemsData,
        newOrderUrl: response.data.event.new_order_url
      });
    })
    .catch((error) => {
      alert("Error loading: " + error);
    });
  }

  applyDiscountCode = (discountCode) => {
    if (discountCode === "") discountCode = null;
    this.setState(
      {loading: true, discountCode: discountCode}, 
      () => {this.getItems()}
    );
  }

  getAcceptedTC = () => {
    if (typeof(Storage) !== "undefined") {
      const acceptedTC = window.localStorage.getItem('acceptedTC');
      const acceptedTCtimestamp = window.localStorage.getItem('acceptedTCTimestamp');

      if (acceptedTC === 'true' && parseInt(acceptedTCtimestamp) >= (Date.now() - 3600000)) {
        this.setState({acceptedTC: true});
      }
    }
  }

  componentDidMount() {
    this.getItems();
    this.getAcceptedTC();
  }

  // Actions
  acceptTC = () => {
    this.setState({
      acceptedTC: true
    });

    window.localStorage.setItem('acceptedTC', true);
    window.localStorage.setItem('acceptedTCTimestamp', Date.now());
  }

  calculateCart = (cartItems) => {
    let total = 0;
    let count = 0;
    for (let [key, item] of Object.entries(cartItems)) {
      let itemData = this.state.itemsData[key];
      total = total + (itemData.price * item.quantity);
      count = count + (item.quantity);
    }
    return [total, count];
  }

  addCartItem = (id, quantity) => {
    let cartItems = this.state.cartItems;
    let newQuantity = 0;
    let itemData = this.state.itemsData[id];

    if (id in this.state.cartItems) {
      if (this.state.cartItems[id].quantity + quantity > itemData.order_maximum) {
        return;
      }
      newQuantity = this.state.cartItems[id].quantity + quantity;
      gtag.addToCart(itemData, quantity);
    } else {
      newQuantity = itemData.order_minimum;
      gtag.addToCart(itemData, quantity);
    }

    cartItems[id] = {
      quantity: newQuantity
    }

    let [total, count] = this.calculateCart(cartItems);
    this.setState({
      cartItems: cartItems,
      cartTotal: total,
      cartCount: count
    });
  }

  removeCartItem = (id, quantity) => {
    let cartItems = this.state.cartItems;

    if(id in this.state.cartItems) {
      cartItems[id].quantity = cartItems[id].quantity - quantity;
      let itemData = this.state.itemsData[id];
      if (cartItems[id].quantity <= 0 || cartItems[id].quantity < itemData.order_minimum) delete cartItems[id];

      gtag.removeFromCart(itemData, quantity);

      let [total, count] = this.calculateCart(cartItems);
      this.setState({
        cartItems: cartItems,
        cartTotal: total,
        cartCount: count
      });
    }
  }

  clearCartItems = () => {
    this.setState({
      cartItems: [],
      cartTotal: 0,
      cartCount: 0
    })
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          actions: {
            acceptTC: this.acceptTC,
            addCartItem: this.addCartItem,
            removeCartItem: this.removeCartItem,
            clearCartItems: this.clearCartItems,
            applyDiscountCode: this.applyDiscountCode,
          }
        }}
      >
      { this.props.children }
      </AppContext.Provider>
    )
  }
}

export const Consumer = AppContext.Consumer;
