import React from 'react';
import Flicking from '@egjs/react-flicking';
import { AutoPlay } from '@egjs/flicking-plugins';

const Cover = ({logo, images}) => {
  return (
    <div>
      { images.length > 0 ? 
        <Flicking id="cover" plugins={images.length > 1 ? [new AutoPlay(3000, "NEXT")] : []} circular autoResize adaptive>
          {images.map(imageSrc => (
            <div key={imageSrc} className="cover-panel" style={{backgroundImage: `url(${imageSrc})`}}></div>
          ))}
        </Flicking>
        : null
      }
      <div id="logo">
        <img src={logo} alt="logo" />
      </div>
    </div>
  )
}

export default Cover;