import React from 'react';
import {
  Container, Row, Col
} from 'react-bootstrap';
import logo from '../images/logo.jpg';
import cover from '../images/cover.jpg';
// import banner1 from '../images/banner-1.jpg';
// import banner2 from '../images/banner-2.jpg';
// import banner3 from '../images/banner-3.jpg';
// import banner4 from '../images/banner-4.jpg';
// import banner5 from '../images/banner-5.jpg';
import banner6 from '../images/banner-6.jpg';
import banner7 from '../images/purra_vittamin_eventpop1200x628.jpg';
import banner8 from '../images/banner-8.jpg';
import Cover from '../components/Cover';
import ItemDisplay from '../components/ItemDisplay';
import FooterBar from '../components/FooterBar';
import DiscountBox from '../components/DiscountBox';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const {i18n} = useTranslation();
  const currentLanguage = i18n.languages[0];
  const terms = {
    en: (
      <Row className="justify-content-center">
      <Col lg={8}>
        {/* <h6>Conditions of Purchase</h6>
        <ol className="list">
          <li>You must be <b>20 years or older</b> to buy alcohol.</li>
          <li>Free delivery for <b>minimum purchase of 500 baht</b>.</li>
        </ol> */}
        <h6>Conditions of Delivery</h6>
        <ol>
          <li>Delivery is restricted only to building of 1-2 storey with no elevator</li>
          <li>Delivery is only available in Bangkok area</li>
          <li>Order will be shipped within 3 days after payment is completed</li>
          <li>Every order purchased is delivered by Singha Online</li>
          <li>No additional delivery fees</li>
        </ol>
      </Col>
      </Row>
    ),
    th: (
      <Row className="justify-content-center">
      <Col lg={8}>
        {/* <h6>เงื่อนไขในการสั่งซื้อผลิตภัณฑ์</h6>
        <ol className="list">
          <li><b>คุณต้องมีอายุ 20 ปีขึ้นไป</b> จึงจะสามารถซื้อสินค้าประเภทแอลกอฮอล์ได้</li>
          <li>การสั่งซื้อผลิตภัณฑ์แต่ละครั้ง <b>ต้องมียอดสั่งซื้อรวมไม่ต่ำกว่า 500 บาท (จัดส่งฟรี)</b></li>
        </ol> */}
        <h6>เงื่อนไขในการจัดส่งผลิตภัณฑ์</h6>
        <ol>
          <li>ขอสงวนสิทธิ์ในการจัดส่งสำหรับอาคารที่ไม่มีลิฟต์แค่ชั้น 1-2 เท่านั้น</li>
          <li>สั่งซื้อออเดอร์จากเว็บไซต์นี้สำหรับการจัดส่งภายในกรุงเทพเท่านั้น**</li>
          <li>จัดส่งภายใน 3 วันทำการ หลังจากชำระเงินเสร็จเรียบร้อย</li>
          <li>จัดส่งผลิตภัณฑ์โดย Singha Online</li>
          <li>ไม่มีค่าบริการจัดส่งเพิ่มเติม</li>
        </ol>
      </Col>
      </Row>
    )
  }

  return (
  <div id="page-home">
    <Container>
    <Row className="justify-content-center">
      <Col lg={8}>
        <Cover logo={logo} images={[
          cover, 
          banner8,
          banner7,
          banner6, 
        ]} />
        <DiscountBox />
        <ItemDisplay />
      </Col>
    </Row>
    <div className="text-light">{terms[currentLanguage]}</div>
    </Container>
    <FooterBar />
  </div>
  )
}

export default Home;