import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import resources from './translations';

i18n
  .use(initReactI18next)
  .use(BrowserLanguageDetector)
  .init({
    resources,
    load: 'languageOnly',
    defaultNS: 'app',
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator']
    }
  });

  export default i18n;