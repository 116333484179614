import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './App.scss';
import { Consumer } from './AppContext';
import Home from './pages/Home';
import TermsAcceptance from './pages/TermsAcceptance';
import MaintenancePage from './pages/Maintenance';

class App extends Component {
  render() {
    return (
      <Consumer>
        {
          ({maintenance, maintenanceMessage, acceptedTC}) => {
            // If we're in maintenance mode
            if (maintenance) {
              return (
                <MaintenancePage message={maintenanceMessage} />
              )
            } else {
              return (
                <Router>
                  <ScrollToTop />
                  {/* {(!acceptedTC) ? <Redirect to="/accept" push={false} /> : null} */}
                  <Switch>
                    <Route path='/accept' component={TermsAcceptance} />
                    <Route exact path='/' component={Home}/>
                  </Switch>
                </Router>
              )
            }
            
          }
        }
      </Consumer>
    );
  }
}

export default App;
