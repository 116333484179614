import React from 'react';
import { useTranslation } from 'react-i18next';
import './EventpopNav.scss';
import {
  Container,
  Navbar,
  Nav
} from 'react-bootstrap';
import logo from './logo.png';

const EventpopNav = (props) => {
  const {i18n} = useTranslation();
  const currentLanguage = i18n.languages[0];

  const handleChangeToTh = (e) => {
    e.preventDefault();
    i18n.changeLanguage('th');
  }

  const handleChangeToEn = (e) => {
    e.preventDefault();
    i18n.changeLanguage('en');
  }

  return (
    <Navbar id="eventpop-navbar" bg="light">
      <Container>
        <Navbar.Brand href="https://www.eventpop.me" target="_blank">
          <img src={logo} alt="Eventpop" />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Nav.Link active={currentLanguage === "th"} onClick={handleChangeToTh}>ไทย</Nav.Link>
          <Nav.Link active={currentLanguage === "en"} onClick={handleChangeToEn}>English</Nav.Link>
        </Nav>
      </Container>

    </Navbar>
  )
}

export default EventpopNav;