import React from 'react';
import logo from '../images/singha-online-logo.png';
import { Consumer } from '../AppContext';
import { Redirect } from 'react-router-dom';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const TermsAcceptance = () => {
  const {t} = useTranslation();

  return (
    <div id="page-terms">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Row className="terms-logo justify-content-center">
              <Col xs={6} sm={4} md={3}>
                <img src={logo} alt="Singha Online" className="img-fluid" />
              </Col>
            </Row>
            <h3 className="text-center">สั่งซื้อสินค้าแอลกอฮอล์</h3>
            <hr />
            <h6>ข้อกำหนดและเงื่อนไขการสั่งซื้อสินค้าประเภทเครื่องดื่มแอลกอฮอล์</h6>
            <ul>
              <li><b>คุณต้องมีอายุ 20 ปีขึ้นไป</b> จึงจะสามารถซื้อสินค้าประเภทแอลกอฮอล์ได้</li>
              <li>เพื่อให้เป็นไปตามข้อกำหนดของกฎหมายทางเว็บไซต์ขอจำหน่ายและจัดส่งเครื่องดื่มแอลกอฮอล์ให้แก่ท่าน<b>ระหว่างเวลา 11:00-14:00 และ 17:00-22:00 น. เท่านั้น</b></li>
            </ul>
            <p>
              * กรุณาเตรียมบัตรประชาชนเพื่อตรวจสอบในขั้นตอนการจัดส่ง
              ดูรายละเอียดเงื่อนไขในการสั่งซื้อแอลกอฮอล์เพิ่มเติม <a href="https://eventpop-help-center-th.helpscoutdocs.com/article/800-article" target="_blank" rel="noopener noreferrer">คลิกที่นี่</a>
            </p>

            <h6>Terms &amp; conditions for ordering alcohal beverage</h6>
            <ul>
              <li>You must be <b>20 years or older</b> to buy alcohol.</li>
              <li>To comply with legal requirements The website requests to sell and deliver alcoholic beverages to you <b>between 11.00-14.00 and 17.00-22.00 hrs only</b>.</li>
            </ul>
            <p>*Please prepare an ID card to check the delivery process. See more details about the conditions for purchasing alcohol. <a href="https://eventpop-help-center-th.helpscoutdocs.com/article/800-article" target="_blank" rel="noopener noreferrer">Click Here</a></p>
            <hr />
            <Consumer>
              {
                ({acceptedTC, actions}) => {
                  const handleAccept = () => {
                    actions.acceptTC();
                  }
                  return (
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col md={6}>
                          <Button className="btn-block" size="lg" variant="success" onClick={handleAccept}>
                            <FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;
                            {t('terms_accept')}
                          </Button>
                        </Col>
                      </Row>
                      {(acceptedTC) ? <Redirect to="/" push={false} /> : null}
                    </div>
                  )
                }
              }
            </Consumer>
            <p className="terms-decline text-center">
              <a href="https://www.eventpop.me/">{t('terms_decline')}</a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  ) 
}

export default TermsAcceptance;