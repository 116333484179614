import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../images/singha-online-logo.png';

import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

const Maintenance = () => {
  const {t, i18n} = useTranslation('maintenance');
  const currentLanguage = i18n.languages[0];

  const message = {
    th: (
      <React.Fragment>
      <p>เรียน สมาชิกทุกท่าน</p>
      <p>เนื่องจากทางเว็บไซต์จะมีการ<b>ปิดปรับปรุงระบบในวันเสาร์ที่ 4 กรกฎาคม 2563 เวลา 23.30 น. - วันจันทร์ที่ 6 กรกฎาคม 2563 เวลา 23.59</b> ดังนั้นทางเว็บไซต์ จึงขอเรียนแจ้งให้ทางสมาชิกที่ต้องการสั่งสินค้าในช่วงเวลาดังกล่าวทราบ เพื่อวางแผนการสั่งซื้อสินค้าในช่วงที่เว็บไซต์เปิดให้บริการ</p>
      <p>ทาง Singha Online ขอขอบพระคุณสมาชิกทุกท่านที่ให้ความสนใจและไว้วางใจในบริการของ Singha Online และหวังเป็นอย่างยิ่งที่จะได้รับการสนับสนุนจากสมาชิกทุกท่านตลอดไป</p>
      <p><b>
        Singha Online &times; Eventpop<br/>
        สอบถามรายละเอียดเพิ่มเติม 02-725-4999 (ทุกวัน 8.00-18.00 น. ยกเว้นวันหยุดนักขัตฤกษ์)
      </b></p>
      </React.Fragment>
    ),
    en: (
      <React.Fragment>
      <p>Dear customers,</p>
      <p>The website will undergo an update from 4th of July 2020 (11.30PM) to 6th of July 2020 (11.59PM) to provide you better services in the future.</p>
      <p>We sincerely apologize for any inconveniences caused and highly appreciate your interest and trust in Singha Online's services. </p>
      <p><b>
        Singha Online &times; Eventpop<br/>
        For more information, please contact 02-725-4999 (Everyday from 8.00AM-6.00PM, excluding public holidays)
      </b></p>
      </React.Fragment>
    )
  }

  return (
    <div id="page-maintenance">
      <Container className="pb-5">
      <Row className="justify-content-center">
        <Col lg={8}>
          <Row className="terms-logo justify-content-center mb-4">
            <Col xs={6} sm={4} md={3}>
              <img src={logo} alt="Singha Online" className="img-fluid" />
            </Col>
          </Row>

          <h4>{t('title')}</h4>
          {message[currentLanguage]}
        </Col>
      </Row>
      </Container>
    </div>
  )
}

export default Maintenance;