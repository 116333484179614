import React from 'react';
import {
  Row,
  Col
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import productImages from '../images/productImages';

const DisabledItem = (props) => {
  const {t} = useTranslation(['items', 'app']);
  
  return (
    <li className="item-group-item item-disabled">
      <Row>
      <Col>
        <img src={productImages[props.id]} alt="" className="img-fluid" />
      </Col>
      <Col className="item-details" xs={7} md={9}>
        <h6 className="item-name">{t(props.name)}</h6>
        <p className="item-description">{t(`items_desc:${props.name}`)}&nbsp;</p>
        <div className="item-price-count">
          <span className="item-price">
            <NumberFormat 
              value={props.price} 
              displayType={'text'}
              prefix={'฿'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true} 
            />
          </span>
          {
            (props.order_minimum > 1) ? <span className="item-minimum-info text-muted"><br/>{t('app:item_order_minimum', {value: props.order_minimum})}</span> : null
          }
        </div>
      </Col>
      <Col xs={1} className="item-count">
      </Col>
      </Row>
    </li>
  )
}

export default DisabledItem;