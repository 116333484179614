const gtagHelper = {
  addToCart: (itemData, quantity) => {
    window.gtag('event', 'add_to_cart', {
      items: [
        {
          "id": itemData.id,
          "name": itemData.name,
          "quantity": quantity,
          "price": itemData.price
        }
      ]
    })
  },
  removeFromCart: (itemData, quantity) => {
    window.gtag('event', 'add_to_cart', {
      items: [
        {
          "id": itemData.id,
          "name": itemData.name,
          "quantity": quantity,
          "price": itemData.price
        }
      ]
    })
  },
  beginCheckout: (cartItems, itemsData) => {
    const items = Object.entries(cartItems).map(([key, value]) => {
      let itemData = itemsData[key];

      return {
        "id": itemData.id,
        "name": itemData.name,
        "quantity": value.quantity,
        "price": itemData.price 
      }
    });

    window.gtag('event', 'begin_checkout', {
      items: items
    })
  }
}

window.gtag('set', { 'currency': 'THB' });

export default gtagHelper;