import React from 'react';
import Item from './Item';
import DisabledItem from './DisabledItem';

const ItemGroup = ({ title, items }) => {
    return (
      <div className="item-group">
        <h5 className="item-group-title">{title}</h5>
        <ul className="item-group-items">
          {items.map(item => item.selling_status === "active" ? <Item key={item.id} {...item} /> : <DisabledItem key={item.id} {...item} />)}
        </ul>
      </div>
  )
}

export default ItemGroup;