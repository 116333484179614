import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import {
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { Consumer } from '../AppContext';
import { useTranslation } from 'react-i18next';
import productImages from '../images/productImages';
import productConfig from '../productConfig';

const Item = (props) => {
  const {t} = useTranslation(['items', 'item_desc', 'app']);

  return (
    <Consumer>
    {
      ({cartItems, actions}) => {
        let quantity = 0;
        if (props.id in cartItems) {
          quantity = cartItems[props.id].quantity;
        }

        let quantityMoreZero = quantity > 0;
        let quantityHitMaximum = quantity >= props.order_maximum;

        const handleIncrement = (e) => {
          actions.addCartItem(props.id, 1);
        }

        const handleDecrement = (e) => {
          actions.removeCartItem(props.id, 1);
        }

        let priceDisplay = null;
        if (productConfig.salesPrice[props.id]) {
          priceDisplay = 
          <React.Fragment>
            <NumberFormat
              value={props.price}
              displayType={'text'}
              prefix={'฿'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              className="sales-price"
              />
            <NumberFormat
              value={productConfig.salesPrice[props.id]}
              displayType={'text'}
              prefix={'฿'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              className="sales-full-price"
            />
          </React.Fragment>;
        } else if (props.discounted_price) {
          priceDisplay = 
          <React.Fragment>
            <NumberFormat
              value={props.discounted_price}
              displayType={'text'}
              prefix={'฿'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              className="sales-price"
              />
            <NumberFormat
              value={props.price}
              displayType={'text'}
              prefix={'฿'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              className="sales-full-price"
            />
          </React.Fragment>;
        } else {
          priceDisplay = 
          <React.Fragment>
            <NumberFormat
              value={props.price}
              displayType={'text'}
              prefix={'฿'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </React.Fragment>;
        }

        return (
          <li className={`item-group-item ${quantityMoreZero ? 'item-selected' : ''}`}>
            <Row>
            <Col className="item-image" onClick={handleIncrement}>
              <img src={productImages[props.id]} alt="" className="img-fluid" />
            </Col>
            <Col className="item-details" xs={7} md={9} onClick={handleIncrement}>
              {productConfig.hasPromotionBadge[props.id] || props.discounted_price ? <Badge className="promotion-badge" variant="danger">{t('app:promotion')}</Badge> : null}
              <h5 className="item-name">{t(props.name)}</h5>
              <p className="item-description">{t(`items_desc:${props.name}`)}&nbsp;</p>
              <div className="item-price-count">
                <span className="item-price">
                  { priceDisplay }
                </span>
                {
                  (props.order_minimum > 1 && !quantityMoreZero) ? <span className="item-minimum-info text-muted"><br/>{t('app:item_order_minimum', {value: props.order_minimum})}</span> : null
                }
                {
                  (quantity > 0) ? <span className="item-quantity">&times; {quantity}</span> : null
                }
              </div>
            </Col>
            <Col xs={1} className="item-count">
              <FontAwesomeIcon
                className={`item-count-button increment-button ${quantityHitMaximum ? '' : 'active-button'}`}
                icon={faPlus}
                onClick={handleIncrement}
              />
              <FontAwesomeIcon
                className={`item-count-button decrement-button ${quantityMoreZero ? 'active-button' : ''}`}
                icon={faMinus}
                onClick={handleDecrement}
              />
            </Col>
            </Row>
          </li>
        )
      }
    }
    </Consumer>
  )
}

export default Item;
