import water_600cc from './grab_singha600-1.jpg';
import water_1500cc from './grab_singha1500-1.jpg';
import singha_620cc from './grab_alc_singha_620_12.jpg';
import singha_320cc from './grab_alc_singha_bottle320.jpg';
import singha_c320cc from './grab_alc_singha_320_24.jpg';
import leo_620cc from './grab_alc_leo_620_12.jpg';
import leo_320cc from './grab_alc_leo_320_bottle24.jpg';
import leo_c320cc from './grab_alc_leo_320_24.jpg';
import leo_c490cc from './grab_alc_leo_490_12.jpg';
import purra_600c from './eventpop_purra600.jpg';
import purra_1500c from './eventpop_purra1500.jpg';
import mashita_chips from './masitachip.jpg';
import lemon_soda_330c from './lemonsoda_300x300.jpg';
import purra_vitamin_500ml from './purra_vittamin_eventpop.jpg';

const productImages = {
  25968: water_600cc,
  25969: water_1500cc,
  25970: singha_620cc,
  25971: singha_320cc,
  25972: singha_c320cc,
  25973: leo_620cc,
  25974: leo_320cc,
  25975: leo_c490cc,
  25976: leo_c320cc,
  26624: purra_600c,
  26625: purra_1500c,
  26626: mashita_chips,
  27920: lemon_soda_330c,
  29064: purra_vitamin_500ml,
}

export default productImages;