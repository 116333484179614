import React from 'react';
import 'url-search-params-polyfill';
import { Consumer } from '../AppContext';
import {
  Row,
  Col,
  Button,
  Container
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faInfoCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import gtag from '../gtagHelper';

const FooterBar = () => {
  const {t} = useTranslation('footer');

  return (
    <Consumer>
      {({discountCode, discountCodeNotFound, cartItems, cartCount, cartTotal, cartMinimum, newOrderUrl, itemsData, actions}) => {
        const reachedCartMinimum = cartTotal >= cartMinimum;

        const handleSubmit = () => {
          let params = new URLSearchParams();
          for (let key in cartItems) {
            params.append(`ticket_types[${key}]`, cartItems[key].quantity);
          }
          if (discountCode !== null && discountCodeNotFound !== true) {
            params.append('discount_code', discountCode);
          }
          gtag.beginCheckout(cartItems, itemsData);
          window.location.href = `${newOrderUrl}?${params.toString()}`;
        }

        return (
          <div id="footer-bar" className={`${cartCount > 0 ? 'show-footer-bar' : null}`}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Row className={`top-mini-bar ${reachedCartMinimum ? "hide-mini-bar" : null}`}>
                    <p><FontAwesomeIcon className="text-info" icon={faInfoCircle} /> {t('minimum_order', {value: `฿${cartMinimum}`})} &middot; <b>{t('free_delivery')}</b></p>
                  </Row>
                  <Row>
                  <Col className="items-summary">
                    <span className="cart-count">{t('items_added', {value: cartCount})}</span>
                    <h5 className="cart-total">
                      <NumberFormat 
                        value={cartTotal} 
                        displayType={'text'}
                        prefix={'฿'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true} 
                      />
                      <FontAwesomeIcon className="cart-clear-button" icon={faTrashAlt} onClick={actions.clearCartItems} />
                    </h5>
                  </Col>
                  <Col xs={5} sm={4} md={4} lg={4}>
                  <Button size="lg" variant="dark" onClick={handleSubmit} disabled={!reachedCartMinimum}>
                    {t('order_button')}
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Button>
                  </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        )
      }}
    </Consumer>
  )
}

export default FooterBar;