import React from 'react';
import { useTranslation } from 'react-i18next';
import { Consumer } from '../AppContext';
import ItemGroup from './ItemGroup';
import productConfig from '../productConfig';

const itemGroupOrder = productConfig.itemGroupOrder;

const ItemDisplay = () => {
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.languages[0];

  return (
    <Consumer>
    {({itemGroups, loading}) => {
      let content = "";

      if (loading) {
      content = (<h6 className="text-light text-center">{t('loading')}</h6>)
      } else {
        content = itemGroupOrder.map(itemGroupId => {
          const itemGroup = itemGroups.find(groupData => groupData.id == itemGroupId);
          return <ItemGroup key={itemGroup.id} title={itemGroup[`name_${currentLanguage}`]} items={itemGroup.ticket_types} />
        });
      }
      return (
        <div className="item-display">
          {content}
        </div>
      )
    }}
    </Consumer>
  )
}

export default ItemDisplay;