const translations = {
  en: {
    app: {
      "loading": "Loading...",
      "terms_accept": "Accept",
      "terms_decline": "I don't accept the terms.",
      "item_order_minimum": "Min. {{value}} items per order",
      "promotion": "Promotion",
      "discount_code_placeholder": "Enter Discount Code",
      "discount_code_apply": "Apply",
      "discount_code_not_found": "This discount code is invalid."
    },
    maintenance: {
      "title": "We'll be back soon!",
    },
    footer: {
      "minimum_order": "Minimum order is {{value}}",
      "free_delivery": "Free delivery for Bangkok",
      "order_button": "Buy",
      "items_added": "{{value}} items added"
    },
    items: {
      "Singha Water (Singha Water 600 cc. x 12 bottles)": "Singha Water 600cc",
      "Singha Water (Singha Water 1,500 cc. x 6 bottles)": "Singha Water 1,500cc",
      "Singha Large Bottle (620 cc. x 12 bottles)": "Singha Large Bottle 620cc",
      "Singha Small Bottle (320 cc. x 24 bottles)": "Singha Small Bottle 320cc",
      "Singha Small Can (320 cc. x 24 bottles)": "Singha Small Can 320cc",
      "LEO Large Bottle (620 cc. x 12 bottles)": "LEO Large Bottle 620cc",
      "LEO Small Bottle (320 cc. x 24 bottles)": "LEO Small Bottle 320cc",
      "LEO Large Can (490 cc. x 12 bottles)": "LEO Large Can 490cc",
      "LEO Small Can (320 cc. x 24 bottles)": "LEO Small Can 320cc",
      "Purra (600cc. x 12 Bottles)": "Purra Mineral Water 600cc",
      "Purra (1,500cc. x 6 Bottles)": "Purra Mineral Water 1,500cc",
      "Masita Chips Salted Egg Potato Chips (90g) & Masita Chips Hot Chili Salted Egg Potato Chips (90g) (Promotion Pack)": "Mashita Potato Chips Salted Egg (90g) & Hot Chili Salted Egg (90g)",
      "Singha Lemon Soda Can (24x330cc)": "Singha Lemon Soda Can 330cc",
      "Purra Vitamin Water (500 ml x 24 Bottles)": "Purra Vitamin Water 500ml",
    },
    items_desc: {
      "Singha Water (Singha Water 600 cc. x 12 bottles)": "Pack of 12 Bottles",
      "Singha Water (Singha Water 1,500 cc. x 6 bottles)": "Pack of 6 Bottles",
      "Singha Large Bottle (620 cc. x 12 bottles)": "Pack of 12 Bottles",
      "Singha Small Bottle (320 cc. x 24 bottles)": "Pack of 24 Bottles",
      "Singha Small Can (320 cc. x 24 bottles)": "Pack of 24 Cans",
      "LEO Large Bottle (620 cc. x 12 bottles)": "Pack of 12 Bottles",
      "LEO Small Bottle (320 cc. x 24 bottles)": "Pack of 24 Bottles",
      "LEO Large Can (490 cc. x 12 bottles)": "Pack of 12 Cans",
      "LEO Small Can (320 cc. x 24 bottles)": "Pack of 24 Cans",
      "Purra (600cc. x 12 Bottles)": "Pack of 12 Bottles",
      "Purra (1,500cc. x 6 Bottles)": "Pack of 6 Bottles",
      "Masita Chips Salted Egg Potato Chips (90g) & Masita Chips Hot Chili Salted Egg Potato Chips (90g) (Promotion Pack)": "1 x 90g Salted Egg, 1 x 90g Hot Chili Salted Egg",
      "Singha Lemon Soda Can (24x330cc)": "Pack of 24 Cans",
      "Purra Vitamin Water (500 ml x 24 Bottles)": "Pack of 24 Bottles",
    }
  },
  th: {
    app: {
      "loading": "กรุณารอสักครู่",
      "terms_accept": "ฉันเข้าใจและยอมรับเงื่อนไข",
      "terms_decline": "ฉันไม่ยอมรับเงื่อนไข",
      "item_order_minimum": "สั่งซื้อขั้นต่ำ {{value}} รายการ",
      "promotion": "โปรโมชั่น",
      "discount_code_placeholder": "ใส่โค้ดส่วนลด",
      "discount_code_apply": "ใช้โค้ด",
      "discount_code_not_found": "ไม่พบโค้ดส่วนลดนี้",
    },
    maintenance: {
      "title": "หยุดบริการชั่วคราว",
    },
    footer: {
      "minimum_order": "ยอดสั่งซื้อขั้นต่ำ {{value}}",
      "free_delivery": "ส่งฟรีทั่วกรุงเทพฯ",
      "order_button": "สั่งซื้อ",
      "items_added": "รวม {{value}} รายการ",
    },
    items: {
      "Singha Water (Singha Water 600 cc. x 12 bottles)": "น้ำสิงห์ 600cc",
      "Singha Water (Singha Water 1,500 cc. x 6 bottles)": "น้ำสิงห์ 1,500cc",
      "Singha Large Bottle (620 cc. x 12 bottles)": "เบียร์สิงห์ใหญ่ 620cc",
      "Singha Small Bottle (320 cc. x 24 bottles)": "เบียร์สิงห์เล็ก 320cc",
      "Singha Small Can (320 cc. x 24 bottles)": "เบียร์สิงห์แคน 320cc",
      "LEO Large Bottle (620 cc. x 12 bottles)": "เบียร์ลีโอใหญ่ 620cc",
      "LEO Small Bottle (320 cc. x 24 bottles)": "เบียร์ลีโอเล็ก 320cc",
      "LEO Large Can (490 cc. x 12 bottles)": "เบียร์ลีโอแคน 490cc",
      "LEO Small Can (320 cc. x 24 bottles)": "เบียร์ลีโอแคน 320cc",
      "Purra (600cc. x 12 Bottles)": "น้ำแร่เพอร์ร่า 600cc",
      "Purra (1,500cc. x 6 Bottles)": "น้ำแร่เพอร์ร่า 1,500cc",
      "Masita Chips Salted Egg Potato Chips (90g) & Masita Chips Hot Chili Salted Egg Potato Chips (90g) (Promotion Pack)": "มันฝรั่งทอดมาชิตะ รสไข่เค็ม (90 กรัม) และ รสฮอตชิลลี่ (90 กรัม)",
      "Singha Lemon Soda Can (24x330cc)": "สิงห์เลมอนโซดา Can 330cc",
      "Purra Vitamin Water (500 ml x 24 Bottles)": "เพอร์ร่า น้ำผสมวิตามิน 500ml",
    },
    items_desc: {
      "Singha Water (Singha Water 600 cc. x 12 bottles)": "แพ็ค บรรจุ 12 ขวด",
      "Singha Water (Singha Water 1,500 cc. x 6 bottles)": "แพ็ค บรรจุ 6 ขวด",
      "Singha Large Bottle (620 cc. x 12 bottles)": "แพ็ค บรรจุ 12 ขวด",
      "Singha Small Bottle (320 cc. x 24 bottles)": "แพ็ค บรรจุ 24 ขวด",
      "Singha Small Can (320 cc. x 24 bottles)": "แพ็ค บรรจุ 24 กระป๋อง",
      "LEO Large Bottle (620 cc. x 12 bottles)": "แพ็ค บรรจุ 12 ขวด",
      "LEO Small Bottle (320 cc. x 24 bottles)": "แพ็ค บรรจุ 24 ขวด",
      "LEO Large Can (490 cc. x 12 bottles)": "แพ็ค บรรจุ 12 กระป๋อง",
      "LEO Small Can (320 cc. x 24 bottles)": "แพ็ค บรรจุ 24 กระป๋อง",
      "Purra (600cc. x 12 Bottles)": "แพ็ค บรรจุ 12 ขวด",
      "Purra (1,500cc. x 6 Bottles)": "แพ็ค บรรจุ 6 ขวด",
      "Masita Chips Salted Egg Potato Chips (90g) & Masita Chips Hot Chili Salted Egg Potato Chips (90g) (Promotion Pack)": "1 ซอง x 90 กรัม รสไข่เค็ม และ 1 ซอง x 90 กรัม รสฮอตชิลลี่",
      "Singha Lemon Soda Can (24x330cc)": "แพ็ค บรรจุ 24 กระป๋อง",
      "Purra Vitamin Water (500 ml x 24 Bottles)": "แพ็ค บรรจุ 24 ขวด",
    }
  }
}

export default translations;